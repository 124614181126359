import { render, staticRenderFns } from "./NotificationsMenu.vue?vue&type=template&id=69c1ade2&scoped=true&"
import script from "./NotificationsMenu.vue?vue&type=script&lang=js&"
export * from "./NotificationsMenu.vue?vue&type=script&lang=js&"
import style0 from "./NotificationsMenu.vue?vue&type=style&index=0&id=69c1ade2&prod&lang=css&"
import style1 from "./NotificationsMenu.vue?vue&type=style&index=1&id=69c1ade2&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "69c1ade2",
  null
  
)

export default component.exports