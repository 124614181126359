

export default {
  q: '؟',
  sar: 'ريال',
  false: 'معطل',
  true: 'مفعل',
  progress: 'جارٍ...',
  id: '#',
  placeholder_example: 'مثال: السنة-الشهر ',
  ar: 'العربية',
  en: 'English',
  login: 'تسجيل الدخول',
  home: 'الرئيسية',
  mainScreenHome: 'تفاصيل المطالبات',
  medicalMainScreenHome: 'متابعة المطالبات',
  carMainScreenHome: 'تفاصيل المطالبات',
  policyMainScreenHome: 'تفاصيل الوثائق',
  legalMainScreenHome: 'شاشة المتابعة',
  done: 'موافق',
  logout: 'تسجيل الخروج',
  cancel: 'إلغاء',
  yes: 'نعم',
  no: 'لا',
  enabled: 'مفعل',
  disabled: 'معطل',
  save: 'حفظ',
  the_number: 'العدد',
  change: 'تعديل',
  options: 'خيارات',
  create: 'إضافة',
  store: 'إضافة',
  update: 'تعديل',
  upload: 'رفع',
  approval: 'تعميد',
  show_notes: 'ملاحظات الموظفين',
  showhistory: 'عرض السجل',
  claim_history: 'سجل المطالبة',
  assign: 'إسناد المطالبة',
  link_to_invoice: 'ربط بفاتورة',
  link_to_bill: 'ربط مطالبات',
  link_to_bill2: 'ربط أقساط',
  unlink: 'إلغاء الربط',
  final_total: 'الاجمالي',
  erp_bill_final_total: 'من المالية - الاجمالي',
  erp_bill_total: 'من المالية - المفوتر',
  erp_bill_vat: 'من المالية - الضريبة',
  car_bill_final_total: 'من المركبات - الاجمالي',
  car_bill_total: 'من المركبات - المفوتر',
  car_bill_vat: 'من المركبات - الضريبة',
  bill: 'فاتورة',
  is_paid: 'مدفوعة',
  is_posted: 'مرحلة',
  claims_count: 'المطالبات',
  bills_count: 'الفواتير',
  installments_count: 'الأقساط',
  rows_count: 'الإجراءات',
  assign_date: 'تاريخ الإسناد',
  assigned_to: 'مسندة إلى',
  pocket_claims: 'المحفظة',
  received_claims: 'المستلمة',
  assigned_claims: 'المسندة',
  collect_claims : 'تعميد تحصيل',
  partial_claims: 'تعميد دفعة قسط',
  final_partial_claims: 'تعميد دفعة قسط نهائي',
  close_claims: 'تعميد إقفال',
  refuse2pay_claims: 'العميل رافض السداد',
  not4clint_claims: 'الرقم ليس للعميل',
  uncompleted_claims: 'نواقص بالمطالبة',
  paid_claims: 'المسددة',
  paid: 'المسدد',
  unposted: 'الغير مرحل',
  unpaid_amount: 'الغير مسدد',
  unbilled_amount: 'الغير مفوتر',
  closed_claims: 'المقفلة',
  pending_claims: 'بانتظار التعميد',
  reassign_claims: 'إعادة إسناد',
  noassign_claims: 'المطالبات الغير المسندة',
  arrange_claims: 'إدارة المطالبات',
  notification_claims25: 'قيد التذكير',
  notification_claims39: 'العميل لديه موعد سداد',
  notification_claims40: 'العميل لا يرد',
  notification_claims41: 'العميل يرغب بالسداد',
  finance_claims: 'المتابعة المالية',
  finance_claim: 'نسبة العمولة',
  notification_claims: 'تذكير',
  suspend_claims: 'المعلقة',
  placed_claims: 'المقدمة',
  collected_claims: 'المحصلة',
  collected_amount: 'المبلغ المحصل',
  collected_amounts: 'المبالغ المحصلة',
  claim_claims: 'مطالبة/ات',
  inprogress_claims: 'تحت الإجراء',
  placeto_claims: 'مرفوعة للجان',
  installment_claims: 'التقسيط',
  month_target: 'بدء / انتهاء المستهدف الشهري',
  download: 'تحميل',
  control: 'تحكم',
  none: 'لا يوجد',
  file_details: 'تفاصيل المرفق',
  file_desc: 'عنوان المرفق',
  file_name: 'اسم الملف',
  file_belong: 'مرفق تابع لـ',
  size: 'الحجم',
  files: 'مرفقات المستند',
  accident_files: 'مرفقات الحادث',
  created_at: 'وقت الإنشاء',
  uploaded_at: 'وقت الرفع',
  na: 'بدون',
  destroy: 'حذف',
  delete: 'حذف',
  show: 'عرض',
  subs: 'حقول الحالة',
  view: 'عرض',
  export: 'تصدير',
  close: 'إغلاق',
  edit: 'تعديل',
  add: 'إضافة',
  employee_notes: 'ملاحظات للموظف',
  employee_note: 'ملاحظة للموظف',
  step_texts: 'خطوات التنفيذ',
  step_text: 'تفاصيل خطوة التنفيذ',
  note_date: 'تاريخ الملاحظة',
  step_date: 'تاريخ التنفيذ',
  register: 'تسجيل',
  print: 'طباعة',
  today: 'اليوم',
  day: 'اليوم',
  date: 'التاريخ',
  now: 'الآن',
  am: 'ص',
  pm: 'م',
  error: 'خطأ !!',
  search: 'بحث',
  username: 'المستخدم',
  the_search: 'البحث',
  ByClient: 'المطالبات حسب العميل',
  ByUser: 'المطالبات المسندة حسب المستخدم',
  claimscount: 'عدد المطالبات',
  exportPdf: 'تصدير البيانات PDF',
  exportExcel: 'تصدير البيانات Excel',
  ImportExcel: 'استيراد البيانات من Excel',
  assign_claims: 'اسناد المطالبات',
  assign_parties: 'اسناد الإجراءات',
  refreshTable: 'تحديث الصفحة',
  refreshData: 'تحديث البيانات',
  getData: 'جلب البيانات',
  expansionPanelHeaderOptions: 'الأدوات',
  close_window: 'اغلاق النافذة',
  year: 'سنة',
  showDetails: 'عرض التفاصيل',
  Details: 'تفاصيل ',
  information: 'معلومات ',
  the_information: 'المعلومات ',
  setting: 'الضبط',
  month: 'شهر',
  this_month: 'هذا الشهر',
  finance_sets_closed: 'المفوترة فقط',
  finance_sets_closed_paid: 'مفوترة تم سدادها',
  by_accident: ' برقم الحادث',
  by_id: ' برقم الهوية',
  by_id_and_others: ' برقم الهوية/ السجل',
  by_legal_id: 'الهوية / السجل التجاري',
  by_case: 'القضية /الدعوى',
  by_request: 'المعاملة',
  by_request_date: 'تاريخ المعاملة',
  by_case_date: 'تاريخ الدعوى',
  by_sadad: ' برقم السداد',
  by_claim: ' برقم المطالبة',
  by_policy: ' برقم الوثيقة',
  by_policy_contract: ' برقم العقد',
  by_client: ' بالعميل',
  by_serial_number: ' بالرقم التسلسلي',
  by_year: ' بسنة العلاج',
  by_hospital: ' بالمستشفى',
  by_hospital_code: ' بكود المستشفى',
  by_city: ' بالمدينة',
  by_division: ' بشعبة المرور',
  by_claim_date: ' بتاريخ التسجيل',
  by_consumer_work: ' بجهة العمل',
  by_plate: '  برقم اللوحة',
  by_mobile: ' برقم الجوال',
  by_mobile_and_others: ' برقم الجوال/الهاتف/الفاكس',
  by_claim_amount: ' بقيمة المطالبة',
  by_name: ' بالاسم',
  non_defined: 'غير محدد',
  greg_letter: ' م',
  hijri_letter: ' هـ',
  import: 'استيراد',
  excelimport: 'ملف الإكسل',
  persons_in_party_vehicle:'المُرافقين ',
  add_consumer:'إضافة مصاب',
  add_accident:'إضافة حادث',
  fromDate:'من تاريخ',
  toDate:'إلى تاريخ',
  entry_actions:'إجراءات مدخل البيانات',
  add_claim:'إضافة مطالبة',
  add_request:'إضافة معاملة',
  view_claim:'عرض مطالبة',
  add_close_claim:'إضافة إغلاق تحصيل مطالبات',
  view_close_claim:'عرض إغلاق تحصيل مطالبات',
  add_party_person:'إضافة مرافق',
  add_party:'إضافة طرف',
  add_party_status:'إضافة حالة مطالبة',
  add_claim_status:'إضافة حالة مطالبة',
  add_party_status_step:'إضافة خطوة تنفيذ إجراء',
  view_active_claim_status:'عرض كل الإجراءات النشطة',
  view_all_claim_status:'عرض كل الإجراءات',
  add_car_claim_status:'إضافة إجراء',
  client_name:'إضافة عميل',
  add_legal_client:'إضافة عميل',
  add_legal_case:'إضافة قضية / دعوى',
  add_legal_party:'إضافة طرف للدعوى',
  status_date_view:'تاريخ إدخال الإجراء',
  is_consumer_view:'المصاب يتبع لهذا الطرف',
  is_consumer_driver_view:'المصاب هو سائق المركبة',
  plate_id:'اللوحة',
  attach_document:'المستندات',
  role: 'مجموعة الصلاحيات',
  reportsearch: 'بحث التقرير',
  reportsearch2: 'بحث التقرير',
  reportsearch3: 'اختر المستخدم',
  one_note: 'ملاحظة: ',
  last_notes: 'اخر ملاحظة: ',
  insurance_details: 'معلومات المؤمن له',
  insurance_name: 'اسم المؤمن له',
  insurance_name_1word: 'المؤمن',
  insurance_identitiy_1word: 'هوية المؤمن',
  insurance_mobile_1word: 'جوال المؤمن',
  claim_details: 'معلومات المطالبة',
  policy_claim_details: 'معلومات الوثيقة/البوليصة',
  accident_details: 'معلومات الحادث',
  accident_city: 'مدينة الحادث',
  party_details: 'الطرف الثاني',
  party_details_name: 'اسم الطرف الثاني',
  paries_details: 'أطراف الحادث',
  car_cashier_screen: 'متابعة التحصيل مركبات',
  policy_cashier_screen: 'متابعة التحصيل وثائق',
  medical_cashier_screen: 'متابعة التحصيل الطبي',
  medical_admin_screen: 'متابعة الطبي العام',
  car_claim_type1:'شامل',
  car_claim_type2:'ضد الغير',
  all_notifications: 'عرض كل التنبيهات',
  multiple_upload: 'رفع ملفات',
  claim_status_assign_type:'نوع اسناد الإجراء',
  assign_type1:'إسناد خاص',
  assign_type2:'إسناد عام',
  in_persons:'مضاف كمرافق بحادث',
  use_in_persons:'ربط بحادث المرافق',
  add_in_persons:'ربط بالحادث',
  parties_numbers:{
    1:  'الأول',
    2:  'الثاني',
    3:  'الثالث',
    4:  'الرابع',
    5:  'الخامس',
    6:  'السادس',
    7:  'السابع',
    8:  'الثامن',
    9:  'التاسع',
    10: 'العاشر',
  },

}
