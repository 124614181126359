

import {defaultLocale, LOCALE_STORAGE_KEY} from '@/app/config'
import {localize} from '@plugins/vee-validate'
import {locales} from '@plugins/vue-i18n/register'
import {camelCase, snakeCase, isString} from 'lodash'
import {mapActions, mapGetters, mapMutations} from 'vuex'

const defaultAxiosCountDown = 50
const selectedSubItem= null;
export default {
  methods: {
    IsAppLocale(locale) {
      return this.AppLocales.find(l => l.code === locale)
    },
    SetAppLocale(locale) {
      this.AppLocale = locale
      this.$nextTick(() => window.location.reload())
    },
    IniAppLocale() {
      this.AppLocale = localStorage.getItem(LOCALE_STORAGE_KEY) || defaultLocale
    },
    getPageTitle() {
      const t = (...a) => this.$t(...a)
      const tc = (...a) => this.$tc(...a)
      const e = (...a) => this.$te(...a)
      const _ = this.$_
      let str = ''

      let {name, pageName} = this.$options || {}
      let routePath = this.$route.path.split('/').pop()
      let routeName = this.$route.name.split('/').pop()
      // console.log(routeName,routePath);
      if(routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      routePath = camelCase(routePath)
      if(routePath && e(routePath) && isString((str = t(routePath)))) {
        return str
      }

      if(routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      routeName = camelCase(routeName)
      if(routeName && e(routeName) && isString((str = t(routeName)))) {
        return str
      }

      let $routes

      $routes = `$routes.${routeName}`
      if(routeName && e($routes) && isString((str = t($routes)))) {
        return str
      }
      let pluralize = _.pluralize(_.upperFirst(routeName.split('Index').join('').split('index').join('')))
      $routes = `choice.${pluralize}`

      if($routes && e($routes) && isString((str = tc($routes, 2)))) {
        return str
      }
      // console.log($routes)
      if(e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if(e(name) && isString((str = t(name)))) {
        return str
      }

      name = camelCase(name)
      if(e(`${name}.name`) && isString((str = t(`${name}.name`)))) {
        return str
      }

      if(e(name) && isString((str = t(name)))) {
        return str
      }

      if(e(pageName) && isString((str = t(pageName)))) {
        return str
      }

      return ''
    },
    getPageName() {
      let returnOBJ = {};
      let routeName = this.$route.name.split('/').pop()
      returnOBJ.name = snakeCase(routeName.split('Index').join('').split('index').join(''))
      returnOBJ.nameStudly = this.$_.upperFirst(returnOBJ.name)
        // console.log(returnOBJ);
      return returnOBJ;
    },
    parseAttribute(string, ...args) {
      if(!string) return string

      const _ = this.$_, t = (...a) => this.$t(...a), te = (...a) => this.$te(...a)

      let key = (this.$helpers.isOnlyObject(string) ? (string.text || '') : string).toString()

      if(te(key) && isString(t(key)))
        return t(key, ...args)

      if(te(`attributes.${key}`) && isString(t(`attributes.${key}`)))
        return t(`attributes.${key}`, ...args)

      return string
    },
    HasEditPermission() {
      return this.HasPermission(this.getPageName().name + '_edit');
    },
    HasDeletePermission() {

      return this.HasPermission(this.getPageName().name+'_delete');
    },
    HasPermission(string) {
      if(!string) return false

      let allowedperms = JSON.parse(localStorage.getItem('perms'));

      if(allowedperms){
        if(allowedperms[string]){
          return true;
        }
      }
      return false
    },
    getAxiosName() {
      return this.$options.name
    },
    getAxiosItems() {
      return this.getIniAxios(this.getAxiosName())
    },
    setIniAxios(name, v) {
      this.$root.iniAxios[name] = v
    },
    getIniAxios(name) {
      return null
    },
    increaseAxios(countdown = defaultAxiosCountDown) {
      countdown = parseInt(countdown) || defaultAxiosCountDown
      this.$root.AxiosCountdown += countdown
      // console.log(this.$root.AxiosCountdown);
      return this.getAxiosCountdown()
    },
    setAxiosCountdown(countdown = defaultAxiosCountDown) {
      this.$root.AxiosCountdown = countdown
    },
    getAxiosCountdown() {
      return this.$root.AxiosCountdown
    },
    queueAxios(func, countdown = defaultAxiosCountDown) {
      // console.log(countdown);
      setTimeout(() => setTimeout(() => this.$nextTick(func), this.increaseAxios(countdown)), 20)
    }
  },
  computed: {
    APP_DEBUG: () => process.env.NODE_ENV === 'development',
    DefaultAppLocale: () => defaultLocale,
    AppRtl: {
      set(v) {
        this.$root.$vuetify.rtl = v
      },
      get() {
        const {rtl} = this.$root.$vuetify || {}
        return rtl !== undefined ? rtl : true
      }
    },
    Permissions: {
      set(v) {

      },
      get() {
        return JSON.parse(localStorage.getItem('perms'));
      }
    },
    AppDirection: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'rtl'
      },
      get() {
        return this.AppRtl ? 'rtl' : 'ltr'
      }
    },
    AppAlign: {
      set(v) {
        this.AppRtl = v.toLowerCase() === 'right'
      },
      get() {
        return this.AppRtl ? 'right' : 'left'
      }
    },
    AppLocales() {
      return locales.map(code => ({title: this.$t(code), code}))
    },
    AppLocale: {
      set(locale = defaultLocale) {
        locale = ('' + locale).toString().toLocaleLowerCase()

        !this.IsAppLocale(locale) && (locale = defaultLocale)

        localStorage.setItem(LOCALE_STORAGE_KEY, locale)
        document.documentElement.setAttribute('lang', locale)
        document.documentElement.setAttribute('dir', locale === 'ar' ? 'rtl' : 'ltr')

        // Vuetify
        this.$root.$i18n.locale = locale
        this.$root.$vuetify.lang.current = locale
        this.$root.$vuetify.rtl = locale === 'ar'

        // axios
        this.$axios.defaults.headers.common['Locale'] = locale

        // Vuex
        const validations = require(`@locales/${locale}/validations.js`).default
        localize(locale, validations.default || validations)

        // moment js
        this.$moment.locale(locale)
      },
      get() {
        return this.$root.$vuetify.lang.current
      }
    },
    themeDark: {
      get() {
        return Boolean(mapGetters('auth', ['themeDark']).themeDark.call(this))
      },
      set(value) {
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeLight: {
      get() {
        // console.log(this.themeDark)
        return !Boolean(this.themeDark)
      },
      set(value) {
        value = !value
        this.$vuetify.theme.dark = value
        mapActions('auth', ['setThemeDark']).setThemeDark.call(this, value)
      }
    },
    themeColor() {
      return this.themeDark ? 'dark' : 'light'
    },
    AppIsMobile() {
      return this.$root.$vuetify.breakpoint.smAndDown || false
    },
    claimStatustypes() {
      return [
        {
          text: this.$t('attributes.claim_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.claim_status2'),
          value: 2
        }
      ]
    },
    Assigntypes() {
      return [
        {
          text: this.$t('attributes.assign_types1'),
          value: 1
        },
        {
          text: this.$t('attributes.assign_types2'),
          value: 2
        }
      ]
    },
    consumer_types() {
      return [
        {
          text: this.$t('attributes.consumer_type0'),
          value: 0
        },
        {
          text: this.$t('attributes.consumer_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.consumer_type2'),
          value: 2
        },
        {
          text: this.$t('attributes.consumer_type3'),
          value: 3
        },
        {
          text: this.$t('attributes.consumer_type4'),
          value: 4
        },
        {
          text: this.$t('attributes.consumer_type5'),
          value: 5
        },
      ]
    },
    genders() {
      return [
        {
          text: this.$t('attributes.gender1'),
          value: 1
        },
        {
          text: this.$t('attributes.gender2'),
          value: 2
        }
      ]
    },
    medicalfiletypes() {
      return [
        {
          text: this.$t('attributes.medicalfiletype0'),
          value: 0
        },
        {
          text: this.$t('attributes.medicalfiletype1'),
          value: 1
        },
        {
          text: this.$t('attributes.medicalfiletype2'),
          value: 2
        }
      ]
    },
    registrationtypes() {
      return [
        {
          text: this.$t('attributes.registrationtype1'),
          value: 1
        },
        {
          text: this.$t('attributes.registrationtype2'),
          value: 2
        },
        {
          text: this.$t('attributes.registrationtype3'),
          value: 3
        },
        {
          text: this.$t('attributes.registrationtype3'),
          value: 3
        },
      ]
    },
    invoicingtypes() {
      return [
        {
          text: this.$t('attributes.invoivingtype0'),
          value: 0
        },
        {
          text: this.$t('attributes.invoivingtype1'),
          value: 1
        },
        {
          text: this.$t('attributes.invoivingtype2'),
          value: 2
        },
        {
          text: this.$t('attributes.invoivingtype3'),
          value: 3
        },
      ]
    },
    medicalaccidentfiletypes() {
      return [
        {
          text: this.$t('attributes.medicalaccidentfiletype0'),
          value: 0
        },
        {
          text: this.$t('attributes.medicalaccidentfiletype1'),
          value: 1
        },
        {
          text: this.$t('attributes.medicalaccidentfiletype2'),
          value: 2
        }
      ]
    },
    regions() {
      return [
        {
          text: this.$t('attributes.regionC'),
          value: 'C'
        },
        {
          text: this.$t('attributes.regionN'),
          value: 'N'
        },
        {
          text: this.$t('attributes.regionE'),
          value: 'E'
        },
        {
          text: this.$t('attributes.regionW'),
          value: 'W'
        },
        {
          text: this.$t('attributes.regionS'),
          value: 'S'
        },
      ]
    },
    carclaimStatustypes() {
      return [
        {
          text: this.$t('attributes.car_claim_status1'),
          value: 1
        },
        {
          text: this.$t('attributes.car_claim_status2'),
          value: 2
        }
      ]
    },
    menutypes() {
      return [
        {
          text: this.$t('attributes.menu_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.menu_type2'),
          value: 2
        }
      ]
    },
    actions_typ() {
      return [
        {
          text: this.$t('attributes.action_ty1'),
          value: 1
        },
        {
          text: this.$t('attributes.action_ty2'),
          value: 2
        }
      ]
    },
    action_categories() {
      return [
        {
          text: this.$t('attributes.action_category1'),
          value: 1
        },
        {
          text: this.$t('attributes.action_category2'),
          value: 2
        }
      ]
    },
    searchmonths() {
      return [
        {
          text: '01-2022',
          value: '01-2022'
        },
        {
          text: '02-2022',
          value: '02-2022'
        },
        {
          text: '03-2022',
          value: '03-2022'
        },
        {
          text: '04-2022',
          value: '04-2022'
        },
        {
          text: '05-2022',
          value: '05-2022'
        },
        {
          text: '06-2022',
          value: '06-2022'
        },
        {
          text: '07-2022',
          value: '07-2022'
        },
        {
          text: '08-2022',
          value: '08-2022'
        },
        {
          text: '09-2022',
          value: '09-2022'
        },
        {
          text: '10-2022',
          value: '10-2022'
        },
        {
          text: '11-2022',
          value: '11-2022'
        },
        {
          text: '12-2022',
          value: '12-2022'
        },
        {
          text: '01-2021',
          value: '01-2021'
        },
        {
          text: '02-2021',
          value: '02-2021'
        },
        {
          text: '03-2021',
          value: '03-2021'
        },
        {
          text: '04-2021',
          value: '04-2021'
        },
        {
          text: '05-2021',
          value: '05-2021'
        },
        {
          text: '06-2021',
          value: '06-2021'
        },
        {
          text: '07-2021',
          value: '07-2021'
        },
        {
          text: '08-2021',
          value: '08-2021'
        },
        {
          text: '09-2021',
          value: '09-2021'
        },
        {
          text: '10-2021',
          value: '10-2021'
        },
        {
          text: '11-2021',
          value: '11-2021'
        },
        {
          text: '12-2021',
          value: '12-2021'
        },
        {
          text: '01-2020',
          value: '01-2020'
        },
        {
          text: '02-2020',
          value: '02-2020'
        },
        {
          text: '03-2020',
          value: '03-2020'
        },
        {
          text: '04-2020',
          value: '04-2020'
        },
        {
          text: '05-2020',
          value: '05-2020'
        },
        {
          text: '06-2020',
          value: '06-2020'
        },
        {
          text: '07-2020',
          value: '07-2020'
        },
        {
          text: '08-2020',
          value: '08-2020'
        },
        {
          text: '09-2020',
          value: '09-2020'
        },
        {
          text: '10-2020',
          value: '10-2020'
        },
        {
          text: '11-2020',
          value: '11-2020'
        },
        {
          text: '12-2020',
          value: '12-2020'
        },
        {
          text: '01-2019',
          value: '01-2019'
        },
        {
          text: '02-2019',
          value: '02-2019'
        },
        {
          text: '03-2019',
          value: '03-2019'
        },
        {
          text: '04-2019',
          value: '04-2019'
        },
        {
          text: '05-2019',
          value: '05-2019'
        },
        {
          text: '06-2019',
          value: '06-2019'
        },
        {
          text: '07-2019',
          value: '07-2019'
        },
        {
          text: '08-2019',
          value: '08-2019'
        },
        {
          text: '09-2019',
          value: '09-2019'
        },
        {
          text: '10-2019',
          value: '10-2019'
        },
        {
          text: '11-2019',
          value: '11-2019'
        },
        {
          text: '12-2019',
          value: '12-2019'
        },
        {
          text: '01-2018',
          value: '01-2018'
        },
        {
          text: '02-2018',
          value: '02-2018'
        },
        {
          text: '03-2018',
          value: '03-2018'
        },
        {
          text: '04-2018',
          value: '04-2018'
        },
        {
          text: '05-2018',
          value: '05-2018'
        },
        {
          text: '06-2018',
          value: '06-2018'
        },
        {
          text: '07-2018',
          value: '07-2018'
        },
        {
          text: '08-2018',
          value: '08-2018'
        },
        {
          text: '09-2018',
          value: '09-2018'
        },
        {
          text: '10-2018',
          value: '10-2018'
        },
        {
          text: '11-2018',
          value: '11-2018'
        },
        {
          text: '12-2018',
          value: '12-2018'
        },
        {
          text: '01-2017',
          value: '01-2017'
        },
        {
          text: '02-2017',
          value: '02-2017'
        },
        {
          text: '03-2017',
          value: '03-2017'
        },
        {
          text: '04-2017',
          value: '04-2017'
        },
        {
          text: '05-2017',
          value: '05-2017'
        },
        {
          text: '06-2017',
          value: '06-2017'
        },
        {
          text: '07-2017',
          value: '07-2017'
        },
        {
          text: '08-2017',
          value: '08-2017'
        },
        {
          text: '09-2017',
          value: '09-2017'
        },
        {
          text: '10-2017',
          value: '10-2017'
        },
        {
          text: '11-2017',
          value: '11-2017'
        },
        {
          text: '12-2017',
          value: '12-2017'
        },
        {
          text: '01-2016',
          value: '01-2016'
        },
        {
          text: '02-2016',
          value: '02-2016'
        },
        {
          text: '03-2016',
          value: '03-2016'
        },
        {
          text: '04-2016',
          value: '04-2016'
        },
        {
          text: '05-2016',
          value: '05-2016'
        },
        {
          text: '06-2016',
          value: '06-2016'
        },
        {
          text: '07-2016',
          value: '07-2016'
        },
        {
          text: '08-2016',
          value: '08-2016'
        },
        {
          text: '09-2016',
          value: '09-2016'
        },
        {
          text: '10-2016',
          value: '10-2016'
        },
        {
          text: '11-2016',
          value: '11-2016'
        },
        {
          text: '12-2016',
          value: '12-2016'
        },
        {
          text: '01-2015',
          value: '01-2015'
        },
        {
          text: '02-2015',
          value: '02-2015'
        },
        {
          text: '03-2015',
          value: '03-2015'
        },
        {
          text: '04-2015',
          value: '04-2015'
        },
        {
          text: '05-2015',
          value: '05-2015'
        },
        {
          text: '06-2015',
          value: '06-2015'
        },
        {
          text: '07-2015',
          value: '07-2015'
        },
        {
          text: '08-2015',
          value: '08-2015'
        },
        {
          text: '09-2015',
          value: '09-2015'
        },
        {
          text: '10-2015',
          value: '10-2015'
        },
        {
          text: '11-2015',
          value: '11-2015'
        },
        {
          text: '12-2015',
          value: '12-2015'
        },
      ]
    },
    DocumentTypes() {
      return [
        {
          text: this.$tc('choice.Consumers',2),
          value: 'Consumer'
        },
        {
          text: this.$t('choice.Accidents',2),
          value: 'Accident'
        },
        {
          text: this.$t('choice.Claims',2),
          value: 'Claim'
        }
      ]
    },
    tolrecGlobals() {
      return [
        {text: '0%', value: 0},
        {text: '25%', value: 25},
        {text: '50%', value: 50},
        {text: '75%', value: 75},
        {text: '100%', value: 100},
        {text: this.$t('non_defined'), value: -1},
      ]
    },
    digittypes() {
      return [
        {
          text: this.$t('attributes.digit_type1'),
          value: 1
        },
        {
          text: this.$t('attributes.digit_type2'),
          value: 2
        }
      ]
    },
    authUser: {
      set(user) {
        mapMutations('auth', ['setUser']).setUser.call(this, user)
      },
      get() {
        return mapGetters('auth', ['getUser']).getUser.call(this)
      }
    },
    apiService() {
      return this.$api.methods
    },
    getdtselecteditems:{
      set(v) {
        this.$root.tdtselecteditems = v
      },
      get() {
        return this.$root.tdtselecteditems
      }
    }
  }
}
