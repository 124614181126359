export default {
  Users: 'المستخدم|المستخدمين',
  InsuranceCompanies: 'شركة تأمين|شركات التأمين',
  CarInsuranceCompanies: 'شركة تأمين|شركات التأمين',
  Consumers: 'المصاب|المصابين',
  CarConsumers: 'المؤمن له|المؤمن لهم',
  AccidentTypes: 'نوع الحادث|أنواع الحوادث',
  CarAccidentTypes: 'نوع الحادث|أنواع الحوادث',
  Cities: 'المدينة|المدن',
  CarCities: 'المدينة|المدن',
  ClaimTypes: 'نوع المطالبة|أنواع المطالبات',
  CarClaimTypes: 'نوع المطالبة|أنواع المطالبات',
  VehicleTypes: 'نوع المركبة|أنواع المركبات',
  CarVehicleTypes: 'نوع المركبة|أنواع المركبات',
  Divisions: 'شعبة المرور|شعب المرور',
  CarDivisions: 'شعبة المرور|شعب المرور',
  Hospitals: 'المستشفى|المستشفيات',
  Accidents: 'الحادث|الحوادث',
  CarAccidents: 'الحادث|الحوادث',
  ClaimStatuses: 'حالة المطالبة|حالات المطالبات',
  ClaimStatusFields: 'حقل حالة المطالبة|حقول حالات المطالبات',
  CarClaimStatuses: 'إجراء المطالبة|إجراءات المطالبات',
  CarClaimStatusFields: 'حقل إجراء المطالبة|حقول إجراءات المطالبات',
  ClaimStatusFields2: 'التوضيح|التوضيحات',
  Claims: 'المطالبة|المطالبات',
  CarClaims: 'المطالبة|المطالبات',
  PartyPersonTypes: 'نوع شخص الطرف|أنواع أشخاص الأطراف',
  CarPartyPersonTypes: 'نوع المرافق|أنواع المرافقين',
  PlateDigits: 'خانة لوحة السيارات|خانات لوحة السيارات',
  Clients: 'العميل|العملاء',
  CarClients: 'العميل|العملاء',
  Years: 'سنة المطالبة|سنوات المطالبات',
  Parties: 'طرف الحادث|أطراف الحادث',
  CarParties: 'طرف الحادث|أطراف الحادث',
  PartyPerson: 'اشخاص طرف الحادث|اشخاص طرف الحادث',
  CarPartyPerson: 'مرافق الحادث|مرافقين الحادث',
  PartyPersons: 'شخص طرف بالحادث|اشخاص اطراف الحادث',
  CarPartyPersons: 'مرافق الحادث|مرافقين الحادث',
  HospitalContacts: 'جهة اتصال المستشفى|جهات اتصال المستشفى',
  ConsumerWorks: 'جهة عمل المصاب|جهات عمل المصابين',
  PartyStatuses: 'حالة المطالبة|حالات المطالبات',
  PartyStatusRows: 'بند حالة المطالبة|بنود حالات المطالبة',
  PartyStatusSteps: 'خطوة تنفيذ إجراء|خطوات تنفيذ الإجراءات',
  VehicleModels: 'موديل المركبة|موديلات المركبات',
  CarVehicleModels: 'موديل المركبة|موديلات المركبات',
  PartyTypes: 'نوع الطرف|أنواع الأطراف',
  Menus: 'قائمة نظام|قوائم النظام',
  Permissions: 'الصلاحية|الصلاحيات',
  Roles: 'المجموعة|المجموعات',
  DocumentTypes: 'نوع المستند|أنواع المستندات',
  PolicyReportones: 'تقرير الشركات|تقرير الشركات',
  PolicyReporttwos: 'تقرير التحصيل|تقرير التحصيل',
  PolicyReportthrees: 'تقرير تحصيل العملاء|تقرير تحصيل العملاء',
  PolicyReportfours: 'تقرير تحصيل الأقساط|تقرير تحصيل الأقساط',
  VehicleReportones: 'تقرير الشركات|تقرير الشركات',
  VehicleReporttwos: 'تقرير التحصيل|تقرير التحصيل',
  VehicleReportthrees: 'تقرير تحصيل العملاء|تقرير تحصيل العملاء',
  VehicleReportfours: 'تقرير تحصيل الأقساط|تقرير تحصيل الأقساط',
  VehicleReportfives: 'تقرير الحالات|تقرير الحالات',
  MedicalReportones: 'تقرير حالة المطالبات|تقرير حالة المطالبات',
  MedicalReporttwos: 'تقرير حالة المطالبات حسب تاريخ الاستلام|تقرير حالة المطالبات حسب تاريخ الاستلام',
  MedicalReportthrees: 'المطالبات المقدمة لشركات التأمین|المطالبات المقدمة لشركات التأمین',
  MedicalReportfours: 'المطالبات المرفوعة للجان|المطالبات المرفوعة للجان',
  MedicalReportfives: 'المطالبات المحصلة|المطالبات المحصلة',
  MedicalReportsixes: 'حالات المطالبات على مستوى مستخدم|حالات المطالبات على مستوى مستخدم',
  MedicalReportsevens: 'كشف التحصیل الشھري|كشف التحصیل الشھري',
  LegalPartyTypes: 'نوع طرف القضية|أنواع أطراف القضايا',
  LegalParties: 'طرف الدعوى|أطراف الدعوى',
  LegalLitigationDegrees: 'درجة التقاضي|درجات التقاضي',
  LegalClientTypes: 'نوع العميل-الموكل|أنواع العملاء-الموكلين',
  LegalClients: 'العميل-الموكل|العملاء-الموكلين',
  LegalCaseTypes: 'نوع القضية|أنواع القضايا',
  LegalCaseStatuses: 'حالة القضية|حالات القضايا',
  LegalCases: 'القضية|القضايا',
  CarRefundReasons: 'سبب الإسترداد|أسباب الإسترداد',
  CarAccidentDestinations: 'جهة حادث|جهات حوادث',
  CarAccidentDestinationTypes: 'نوع جهة حادث|أنواع جهات حوادث',
  CarEntryActions: 'إجراء مدخل البيانات|إجراءات مدخل البيانات',
  CarEntryActionTypes: 'نوع إجراء المدخل|أنواع إجراءات المدخل',
  CarVehicleKinds: 'طراز المركبة|طراز المركبات',
  ClaimStatusRowDocuments: 'مرفق إجراء|مرفقات إجراء',
  CarClaimDocuments: 'مرفق مطالبة|مرفقات المطالبة',
  CarSmsTemplates: 'قالب رسالة نصية|قوالب رسائل نصية',
  ClaimStatusRows: 'اجراء مطالبة|اجراءات المطالبات',
  BusinessTypes: 'حجم الأعمال|أحجام الأعمال',
  PolicyClaims: 'مطالبة وثيقة/بوليصة|مطالبات وثائق/بوالص',
  PolicyClients: 'العميل|العملاء',
  PolicyClaimStatusRows: 'اجراء مطالبة|اجراءات المطالبات',
  PolicyClaimStatuses: 'إجراء المطالبة|إجراءات المطالبات',
  PolicyClaimStatusFields: 'حقل إجراء المطالبة|حقول إجراءات المطالبات',
  PolicyCities: 'المدينة|المدن',
  ContractTypes: 'نوع العقد|أنواع العقود',
  MedicalDischargeConditions: 'الحالة الصحية عند الخروج|حالات الخروج الصحية',
  MedicalAccidentDestinations: 'مباشر الحادث|مباشرين الحوادث',
  MedicalConditions: 'الحالة الصحية|الحالات الصحية',
  Bills: 'الفاتورة|الفواتير',
  LegalRequestTypes: 'نوع المعاملة|أنواع المعاملات',
  LegalContractTypes: 'نوع التعاقد|أنواع التعاقد',
  LegalCaseSessions: 'الجلسة|الجلسات',
  LegalCaseCategories: 'تصنيف القضية|تصانيف القضايا',
  LegalAssignedLegalCases: 'اسناد القضية|اسناد القضايا',
  LegalActionRequests: 'طلب إجراء|طلبات الإجراءات',
  LegalActionRequestUpdates: 'تحديث طلب إجراء|تحديثات طلب الإجراء',
  LegalActionFields: 'حقل إجراء|حقول الإجراءات',
  LegalActionFieldRows: 'حقل إجراء|حقول الإجراءات',
  LegalActions: 'الإجراء|إجراءات القضايا',
  MedicalClaims: 'المطالبة|المطالبات',
  LegalRequests: 'المعاملة|المعاملات',
};
